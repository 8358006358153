@import 'styles/layers.scss';

@layer uikit {
  .icon {
    color: var(--grey);
    margin-right: 2px;
    &-focused {
      color: var(--white);
    }
  }

  .option, .single-value {
    display: flex;
    align-items: center;
  }

  .dot {
    background-color: var(--yellow);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 4px;
  }
}