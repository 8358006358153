@import 'styles/layers.scss';

@layer uikit {
  .select-wrap {
    width: 100%;
  }
  
  .select-label {
    margin-bottom: 4px;
  }

  .description {
    font-size: 12px;
    opacity: 0.3;
    margin-bottom: 8px;
  }
}