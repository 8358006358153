@import 'styles/layers.scss';

@layer component {
  .title {
    margin-bottom: 20px;
  }
  
  .tableContainer {
    max-width: 679px;
  }
  
  .thead {
    opacity: 0.5;
    background: transparent !important;
  }
  
  
  .td, .th {
    padding-top: 12px;
    padding-bottom: 10px;
    &:first-child{
      padding-left: 0;
    }
  }
  
  .nameColEllipsis, .nameCol {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--blue3);
  }
  
  .nameCol {
    cursor: pointer;
  }
  
  @media screen and (max-width: 1120px) {
    .tableContainer {
      overflow-y: auto;
    }
  }
  
}